import React, { useEffect, useState } from "react";
import { Criteo } from "~/components/Criteo/Criteo";
import SEO from "~/components/seo";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { useStaticQuery, graphql } from "gatsby";
import { removeDuplicates } from "~/helpers";
import {
  SearchHeader,
  SearchResults,
  SearchFooter
} from "~/components/LocationPage";
import { PageContainer } from "~/components/.base/containers";
import "./locations.scss";
import { weekDays, reformatArr } from "~/helpers";
import * as moment from "moment-timezone";
import axios from "axios";
import _ from "lodash";
const SearchLocations = ({ location }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [distance, setDistance] = useState(5);
  const [stateQuery, setStateQuery] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const isWithinDistance = (truck) => {
    var isWithinDistance;
    for (let eventDay of Object.values(truck.events)) {
      if (eventDay.length) {
        for (let event of eventDay) {
          if (event.isWithinDistance) isWithinDistance = true;
        }
      }
    }
    return isWithinDistance;
  };
  const countDistanceAndDeriveMaxEventDepth = (truck, isState) => {
    var count = 0;
    var maxLength = 1;
    for (let eventDay of Object.values(truck.events)) {
      if (eventDay.length) {
        if (eventDay.length > maxLength) {
          maxLength = eventDay.length;
        }
        for (let event of eventDay) {
          if (isState) {
            count++;
          } else {
            if (event.isWithinDistance) count++;
          }
        }
      }
    }
    return {
      ...truck,
      withinDistanceCount: count,
      maxEventDepth: maxLength
    };
  };
  const contentfulTrucks = useStaticQuery(graphql`
    query FoodTruckQuery {
      allContentfulFoodTruckPage {
        edges {
          node {
            title
            handle
            calendarId
            calendarIds
            phoneNumber
            emailAddress
            isATruck
            heroImage {
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            states
            # restaurantSchedule {
            #   title
            #   mondayHours
            #   tuesdayHours
            #   wednesdayHours
            #   thursdayHours
            #   fridayHours
            #   saturdayHours
            #   sundayHours
            #   mondayNote
            #   tuesdayNote
            #   wednesdayNote
            #   thursdayNote
            #   fridayNote
            #   saturdayNote
            #   sundayNote
            # }
          }
        }
      }
    }
  `);
  useEffect(() => {
    if (contentfulTrucks) {
      (async () => {
        const params = new URLSearchParams(window.location.search);
        const query = params.get("q");
        const dist = params.get("dist");
        const state = params.get("state");
        setSearchQuery(query);
        if (dist) {
          setDistance(dist);
        }
        if (state) {
          setStateQuery(state);
        }
        const reqUrl = query
          ? `${process.env.GATSBY_SERVER_API_URL}/trucks/schedule`
          : `${process.env.GATSBY_SERVER_API_URL}/trucks/state-events`;
        // const reqUrl = query
        // 	? `http://localhost:3000/trucks/schedule`
        // 	: `http://localhost:3000/trucks/state-events`;
        var truckResp;
        try {
          const allLocations = removeDuplicates(
            contentfulTrucks.allContentfulFoodTruckPage.edges.map((edge) => ({
              name: edge.node.title,
              title: edge.node.title,
              handle: edge.node.handle,
              heroImage: edge.node.heroImage,
              calendarId: edge.node.calendarId,
              calendarIds: edge.node.calendarIds,
              isATruck: edge.node.isATruck,
              phoneNumber: edge.node.phoneNumber,
              emailAddress: edge.node.emailAddress,
              restaurantSchedule: edge.node.restaurantSchedule,
              states: edge.node.states ? edge.node.states.split(", ") : ""
            })),
            "name"
          );

          const trucks = allLocations.filter((l) => l.isATruck);
          if (query) {
            truckResp = await axios.post(reqUrl, {
              truckStates: allLocations.map((loc) => ({
                name: loc.name,
                calendarId: loc.calendarIds?.join(', '),
                states: loc.states
              })),
              zipCode: query,
              dist
            });
          } else {
            truckResp = await axios.post(reqUrl, {
              truckStates: trucks,
              state
            });
          }
          if (truckResp.data.message === "no results found") {
            setIsLoading(false);
            setSearchResults([]);
            return;
          }
          let filteredTrucks;
          if (dist) {
            filteredTrucks = truckResp.data
              .filter((truck) => isWithinDistance(truck))
              .map((truck) => countDistanceAndDeriveMaxEventDepth(truck));
          } else if (state) {
            filteredTrucks = truckResp.data.map((truck) =>
              countDistanceAndDeriveMaxEventDepth(truck, true)
            );
          }
          filteredTrucks = filteredTrucks.map((truck) => {
            const contentfulDetailsArr = contentfulTrucks.allContentfulFoodTruckPage.edges.filter(
              (contentfulTruck) => {
                return contentfulTruck.node.calendarIds?.join(', ') === truck.calendarId;
              }
            );
            if (!contentfulDetailsArr.length) return { ...truck };
            const contentfulDetails = contentfulDetailsArr.find(
              (contentfulTruck) => contentfulTruck.node.title === truck.name
            )
              ? contentfulDetailsArr.find(
                  (contentfulTruck) => contentfulTruck.node.title === truck.name
                )
              : contentfulDetailsArr[0];
            return { ...truck, contentfulDetails };
          });

          filteredTrucks.sort((a, b) => {
            var distanceA = a.withinDistanceCount,
              distanceB = b.withinDistanceCount;
            if (!a.contentfulDetails.node.isATruck) {
              distanceA *= 2;
            }
            if (!b.contentfulDetails.node.isATruck) {
              distanceB *= 2;
            }
            return distanceB - distanceA;
          });
          filteredTrucks = filteredTrucks.map((truck) => {
            let d = moment()
              .tz("America/Los_Angeles")
              .startOf("day")
              .get("day");
            const orderedWeekdays = weekDays
              .slice(d, 7)
              .concat(weekDays.slice(0, d));
            const scheduleDataArr = orderedWeekdays.map((day) =>
              truck.events[day] ? _.uniqBy(truck.events[day], "summary") : null
            );
            scheduleDataArr.forEach((day) => {
              if (day) {
                day.sort((a, b) => {
                  if (!a.start || !b.start) return 0;
                  const aStart = new Date(a.start);
                  const bStart = new Date(b.start);
                  const truckOffset = Number(
                    a.start.split("-")[3].split(":")[0]
                  );
                  const localOffset = aStart.getTimezoneOffset() / 60;
                  const diff = localOffset - truckOffset;
                  var aHours = aStart.getHours() + diff;
                  var bHours = bStart.getHours() + diff;
                  if (aHours <= 0) aHours += 24;
                  if (bHours <= 0) bHours += 24;
                  var aAMPM = "AM",
                    bAMPM = "AM";
                  if (aHours > 12) {
                    aHours -= 12;
                    aAMPM = "PM";
                  }
                  if (bHours > 12) {
                    bHours -= 12;
                    bAMPM = "PM";
                  }
                  if (aAMPM === "AM" && bAMPM === "PM") return -1;
                  if (bAMPM === "AM" && aAMPM === "PM") return 1;
                  if (aHours > bHours) return 1;
                  if (aHours < bHours) return -1;
                  return 0;
                });
              }
            });
            return {
              ...truck,
              events: reformatArr(scheduleDataArr)
            };
          });

          setIsLoading(false);
          setSearchResults(filteredTrucks);
        } catch (err) {
          console.log(err);
          setIsLoading(false);
          setSearchResults([]);
        }
      })();
    }
  }, [contentfulTrucks]);
  return (
    <>
      <SEO title="Search Locations" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <PageContainer className="locations-search">
        <SearchHeader
          searchQuery={searchQuery}
          stateQuery={stateQuery}
          distance={distance}
          setDistance={setDistance}
        />
        <SearchResults
          isLoading={isLoading}
          searchResults={searchResults}
          searchQuery={searchQuery}
          stateQuery={stateQuery}
        />
        <SearchFooter />
      </PageContainer>
    </>
  );
};

export default SearchLocations;
